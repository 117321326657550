import {useContext, useEffect, useState} from 'react';
import {DmarcContext} from './context';
import {RAG} from './const';
import {dmarcUserDomains} from './dmarcUserDomains';
import './App.css';
import {Alert, Modal} from 'react-bootstrap';
import {Icon} from  './const';
import { setDateByDays } from './ViewDashboard'

import {ContentFsm} from './const';
import {UserContext, MenuPathContext} from './context'

import 'bootstrap/dist/css/bootstrap.min.css'
import { Table as BTable, Button, Col, Row, Spinner, Container } from 'react-bootstrap'

import { DomainsCols, DmarcData, Aggregate, Domains, Summary, DmarcDomainResponse, Statistics, History } from './types'
import { TableDetail } from './TableDetail'

import { Table } from './table'

import { createColumnHelper, ColumnDef,CellContext } from '@tanstack/react-table'

import { dmarcReport } from './dmarcReport'
import { dmarcDomain } from './dmarcDomain'
import { LoadingFsm } from './const'

import { dmarcSummary } from './dmarcSummary'
import { dmarcHistory } from './dmarcHistory'
import { dmarcSortReports, dmarcSortSummary, dmarcSortHistory} from './dmarcSort'

import { dmarcStatistics } from './dmarcStatistics'

import { ViewSender} from './ViewSender'
import { info } from 'console';

import { ViewDomainDetail } from './ViewDomainDetail'
import { ViewDomainStatistcs } from './ViewDomainStatistcs'
import { ViewDashboard } from './ViewDashboard'
import { ViewDomainHistory } from './ViewDomainHistory'

import {useNavigate, useLocation} from 'react-router-dom';
import { domainToASCII } from 'url';

//const columnHelper = createColumnHelper<DomainsCols>();

const columnHelper = createColumnHelper<Summary>();


const columns:any[] = [
  columnHelper.display( {
    size: 40,
    header: ({ table }) => (
      <>
        <Button variant="outline-light"
          {...{
            onClick: table.getToggleAllRowsExpandedHandler(),
          }}
        >
          <div style={{color: '#000'}}>
              {table.getIsAllRowsExpanded() ? Icon.EXPANDED : Icon.CONTRACTED}
          </div>
        </Button>{' '}
      </>
    ),
    id: 'expand',
    cell: ({ row, getValue }) => (
      <div
        style={{
          // Since rows are flattened by default,
          // we can use the row.depth property
          // and paddingLeft to visually indicate the depth
          // of the row
          paddingLeft: `${row.depth * 2}rem`,
        }}
      >
        <>
          {!row.getCanExpand() ? (
            <Button variant="outline-light"
              onClick={() => row.toggleExpanded()}
              {...{
                //onClick: row.getToggleExpandedHandler(),   
                style: { cursor: 'pointer' }
              }}
            >
              <div style={{color: '#000'}}>
                  {row.getIsExpanded() ? Icon.EXPANDED : Icon.CONTRACTED }
              </div>
            </Button>
          ) : (
            '🔵'
          )}{' '}
          {getValue()}
        </>
      </div>
    ),
  }),
  columnHelper.accessor('source_domain', {
    size: 40,
    cell: info => info.getValue(),
    header: () => <span>Sending Domain</span>,
    //footer: props => props.column.id
  }),
  columnHelper.accessor('count', {
    size: 40,
    cell: info => info.getValue(),
    header: () => <span>count</span>,
    //footer: props => props.column.id
  }),
  columnHelper.accessor('dmarc_count', {
    size: 40,
    //cell: info => info.getValue(),
    cell: ({row, getValue}) =>  <td className={'ragTd'} style={{backgroundColor: rag(getValue()/row.original.count)}}>
                                {`${percent(getValue()/row.original.count)}%`}
                                </td>,
                                
    header: () => <span>dmarc</span>,
    //footer: props => props.column.id
    meta: { 
        TD: null,  
    }

    //footer: props => props.column.id
  }),
  columnHelper.accessor('spf_count', {
    size: 40,
    //cell: ({row, getValue}) => <td><span style={{backgroundColor: row.original.spf_rag}}>{getValue()}</span></td>,
    //cell: ({row, getValue}) => <td style={{backgroundColor: row.original.spf_rag}}>{getValue()}</td>,
    cell: ({row, getValue}) =>  <td className={'ragTd'} style={{backgroundColor: rag(getValue()/row.original.count)}}>
                                {`${percent(getValue()/row.original.count)}%`}
                                </td>,
                                
    
    header: () => <span>spf</span>,
    //footer: props => props.column.id
    meta: { 
        TD: null,  
    }

  }),
  columnHelper.accessor('dkim_count', {
    size: 40,
    //cell: info => info.getValue(),

    //<div class="tooltip">Hover over me 
    //<span class="tooltiptext">This is tooltiptext.</span> 

    cell: ({row, getValue}) =>  <td className={'ragTd'} style={{backgroundColor: rag(getValue()/row.original.count)}}>
                                {`${percent(getValue()/row.original.count)}%`}
                                </td>,
                                
    
    header: () => <span>dkim</span>,
    //footer: props => props.column.id
    meta: { 
        TD: null,  
    }

  }),
  columnHelper.display( {
    id: 'padding',
    //cell: info => info.getValue(),
    header: () => '',
    //footer: props => props.column.id
    meta: { 
        className: 'table-padding',  
    }
  })

]

const percent = (value:number) => {
    return(Math.floor(value*100));
}

const rag:any = (value:number) => {
    var color:string;
    switch (value) {
        case 0:   color = RAG.Red;          break;
        case 1:   color = RAG.Green;        break;
        default:  color = RAG.Amber;  
    }
    return (color); 
};


// this component is used when a user wants to view the Domain details (normally clicked from a table expand)
// see TableDetail
// it is purely prsent to cause a navigation redirect - adding the domain name as part of the url
// the same path may be used to directly invoke the view of the domain (assuming the user is logged in)

// component heirachy:  <ViewUser/> --> <ViewDomains/> --> <NavToViewDomain/> --> <ViewDomain/> --> <Domain/>
// paths                  /domain                                                    /domain/*             

interface NavToViewDomain {
    data: any;
}

export const NavToViewDomain = (props:NavToViewDomain) => {  
    const navigate = useNavigate();

    useEffect(() => {
        console.log('NavToViewDomain useEffect', props);
        navigate(`/user/domain/${props.data.domain}`);
    }, []);
            
    return null;
}

  
// this component  is called by routing and uses the path to invoke <Domain />
// NavToViewDomain, ViewDomain & Domain work together to allow a direct URL or based on user selection -- to display domain details 
// most of the logic is taken from ViewDomains - this component calls the base code to set up the list of alloed domains, so that a user may invke from the URL

// '', 'user', 'domain', <domain>, 'begin' , <begin>, 'end' , <end>
//  0     1       2         3         4         5       6       7

export const ViewDomain = () => {  
    const { dmarc, setDmarc } = useContext(DmarcContext);
    const [ fsm, setFsm ] = useState(LoadingFsm.UNDEFINED);

    console.debug('ViewDomain', dmarc);

    const location = useLocation();
    const path = location.pathname.split('/'); 
    console.log('PATH', path);
    
    //const domain:string = path[path.length-1];
    const domain:string = (path.length > 3) ? path[3] : '';                // 3rd parameter is the domain name 
    const begin:string  = (path.length > 5) ? path[5] : '';                // 5th parameter is the begin range
    const end:string    = (path.length > 7) ? path[7] : '';                // 7th parameter is the end range


    useEffect(() => {
        console.log('useEffect', dmarc.length);

        if (fsm === LoadingFsm.UNDEFINED) {
            if (dmarc.length > 0) {
                setFsm(LoadingFsm.FOUND);
            } else {
                setFsm(LoadingFsm.INITIALISING);     
                dmarcUserDomains()
                .then(report => { 
                    setFsm((report.Items.length === 0) ? LoadingFsm.EMPTY : LoadingFsm.FOUND);
                    setDmarc([...dmarc, ...report.Items.map((item:any) => ({domain: item}))]);
                    console.log('ViewDomains useEffect data', dmarc, report);
                });
            };
        };
    }, []);
  
    console.log('ViewDomain fsm:', fsm);
    
    switch (fsm) {
      case LoadingFsm.UNDEFINED:
      case LoadingFsm.INITIALISING:
        return (
          <Container fluid="lg" className="content_container">
            <Row>
                <Col>
                    <div> <Spinner  animation="border" /></div>
                </Col>
            </Row>
          </Container>
        );
        break; 
      case LoadingFsm.EMPTY:
        return (
          <Container fluid="lg" className="content_container">
            <Row>
                <Col>
                    <div>Invalid Domain</div>
                </Col>
            </Row>
          </Container>
        );
        break; 
      case LoadingFsm.FOUND:
      default:
        return ( 
            <Domain domain={domain} begin={begin} end={end}/>
        );
    };
};


interface Domain {
    domain: string;
    begin: string;
    end: string;
}
export const Domain = (props:Domain) => {  
    const { dmarc, setDmarc } = useContext(DmarcContext);
    const [ fsm, setFsm ] = useState(LoadingFsm.UNDEFINED);

    console.debug('Domains', dmarc, props);

    //const domain:string = props.data.domain;

    const domain:string = props.domain;

    const beginProp:string = props.begin;
    const endProp:string   = props.end;

    const onChangeDate = (startDate:string) => {

      console.log('onChangeDate', startDate);

      const dmarcIndex = dmarc.findIndex(item => item.domain === domain)
      if (dmarcIndex >= 0) {


        console.log('onChangeDate', startDate, dmarcIndex);

        const newDmarc = [...dmarc];
        newDmarc[dmarcIndex].startDate = startDate;
        //newDmarc[dmarcIndex].endDate = endDate;

        //setDmarc([...dmarc, ...newDmarc]);                   // save the report and summary back int
        
        setDmarc(newDmarc);  
        setFsm(LoadingFsm.REFRESH);
      }
      
    }

    useEffect(() => {
        console.log('useEffect');

        if (fsm ===  LoadingFsm.UNDEFINED) {

            const dmarcIndex = dmarc.findIndex(item => item.domain === domain);
            if (dmarcIndex < 0) {
               
                setFsm(LoadingFsm.EMPTY);                                       // no match for valid domains
            
            } else if (dmarc[dmarcIndex].hasOwnProperty('report')) {
               
                setFsm((dmarc[dmarcIndex].report?.length === 0) ? LoadingFsm.EMPTY : LoadingFsm.FOUND);
            
            } else {

                setFsm(LoadingFsm.REFRESH);   

            };
        };
    }, []);


    useEffect(() => {

      console.log('Fsm useEffect', fsm);

      if (fsm ===  LoadingFsm.REFRESH) {

        setFsm(LoadingFsm.INITIALISING);   

        const dmarcIndex = dmarc.findIndex(item => item.domain === domain);

        let report:Aggregate[] = [];

        // beginProp and endProp may be passed in in thr path to genetate bespoke time tanges (debugging)
        const startDate = (dmarcIndex >=0 && dmarc[dmarcIndex].hasOwnProperty('startDate')) ?  dmarc[dmarcIndex].startDate! : (beginProp === '') ? '-7' : beginProp;
        const endDate   = (dmarcIndex >=0 && dmarc[dmarcIndex].hasOwnProperty('endDate'))   ?  dmarc[dmarcIndex].endDate!   : (endProp === '')   ? '-1' : endProp;

        
        dmarcReport({domain: domain, begin: setDateByDays(startDate), end: setDateByDays(endDate)})  // get the dmarc reports for the domain  ** API CALL **
        .then(result => {
            report = result;

            // get a list of all the sending IPs and deduplicate it
            const ipList = report.map((item) => (item.source_ip));
            const dedup = Array.from(new Set(ipList));

            console.log('   ', ipList.length, dedup.length);

            dmarcDomain(dedup)
            .then(result => {       // get a list of domains and servers from a de-duped ip list ** API CALL **
                //let sendDomains:DmarcDomainResponse[] = result;
                let sendDomains:DmarcDomainResponse[] = result;
        
                // merge the list of domaind/servers back into the report
                //let reportWithDomains:Aggregate[] = report.map((item:Aggregate) => { 
                report = report.map((item:Aggregate) => { 
                
                    const match = sendDomains.find((domain:DmarcDomainResponse) => domain.ip == item.source_ip);
                    if (match !== undefined) {
                        item.source_ptr     = match.host;
                        item.source_domain  = match.domain;
                    }
                    return (item);
                });
                report  = dmarcSortReports(report);                       // sort the report

                let summaryReport = dmarcSummary(report);                 // create a summary report
                summaryReport     = dmarcSortSummary(summaryReport);      // create a summary report
    
                //let historyReport = dmarcHistory(report);                 // create a summary report
                //historyReport     = dmarcSortHistory(historyReport);      // create a summary report
    
                const statistics  = dmarcStatistics(summaryReport);       // create statistics

                //let selectors = report.map(item => (item.dkim_selector));  // create a list of dkim selectors
                //selectors = Array.from(new Set(selectors));                // filyter out diplicates
                //selectors = selectors.filter(item => ((item !== '') && (item !== undefined)));         // delete any blank entry
                //selectors = selectors.filter(item => !(item in ['', undefined]));                    // delete any blank entry
    
    
                //console.log('SUMMARY', summaryReport, selectors);
    
                const newDmarc = [...dmarc];
                newDmarc[dmarcIndex].report = report;       // all the reports

     
                newDmarc[dmarcIndex].summary = summaryReport;          // summary by sender
                //newDmarc[dmarcIndex].history = historyReport;          // summary by date
                newDmarc[dmarcIndex].statistics = statistics;          // count summaries // obytained frome smmar because its faster that from reports

                //newDmarc[dmarcIndex].dkimSelectors = selectors;        // lists all of dkim slectors used 

                newDmarc[dmarcIndex].startDate = startDate;            // requested start date (offset) for the reports
                newDmarc[dmarcIndex].endDate = endDate;                // requested end date (offset) for the reports

                //setDmarc([...dmarc, ...newDmarc]);                   // save the report and summary back int
                
                setDmarc(newDmarc);                                    // save the report and summary back int
    
                console.log('loadReport out', newDmarc);
    
                setFsm(LoadingFsm.FOUND);
              
            });          
          });
        };
    }, [fsm]);


  console.log('ViewDomain fsm:', fsm);

  switch (fsm) {
    case LoadingFsm.UNDEFINED:
    case LoadingFsm.INITIALISING:
    case LoadingFsm.REFRESH:
    default:
      return (
        <Container fluid="lg" className="content_container">
          <Row>
              <Col>
                  <div> <Spinner  animation="border" /></div>
              </Col>
          </Row>
        </Container>
      );
      break; 
    case LoadingFsm.EMPTY:
      return (
        <Container fluid="lg" className="content_container">
          <Row>
              <Col>
                  <div>No Domain Data Found</div>
              </Col>
          </Row>
        </Container>
      );
      break; 
    case LoadingFsm.FOUND:
       // zzz FULL const data:Aggregate[] = dmarc.domain[dmarcIndex].report;
     

       console.log('XXXXXXXXXXXXXXXXXXXX', dmarc, dmarc.length);
        //const dmarcIndex = (dmarc.domain.length !== 0) ? dmarc.domain.findIndex(item => item.domain === domain) : -1;
        const dmarcIndex = dmarc.findIndex(item => item.domain === domain);


          
        const summary:Summary[]      = dmarc[dmarcIndex].hasOwnProperty('summary')       ? dmarc[dmarcIndex].summary!       : [];
        const dkimSelectors:string[] = dmarc[dmarcIndex].hasOwnProperty('dkimSelectors') ? dmarc[dmarcIndex].dkimSelectors! : [];
        const statistics:any         = dmarc[dmarcIndex].hasOwnProperty('statistics')    ? dmarc[dmarcIndex].statistics!    : {};
        //const history:History[]      = dmarc[dmarcIndex].hasOwnProperty('history')       ? dmarc[dmarcIndex].history!       : [];
        const report:Aggregate[]     = dmarc[dmarcIndex].hasOwnProperty('report')        ? dmarc[dmarcIndex].report!       : [];
   
       
        console.log('View Domain+++++++++++++++++++++',dmarcIndex, dmarc, summary);

        return ( 
            <>
                <ViewDashboard domain={domain} onChangeDate={onChangeDate}/> 
                <ViewDomainHistory domain={domain} report={report}/>
                <ViewDomainStatistcs domain={domain} statistics={statistics}/>
                <ViewDomainDetail domain={domain} report={report}/>
                { summary.length>0 ? <TableDetail columns={columns} data={summary} Component={ViewSender}/> : <h2>No Report Data Found</h2>}
            </>
        );
    };
};
