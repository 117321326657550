



export const Home = () => {
    console.debug('Home');
    const pStyle = {color: '#ff0000', backgroundColor: '#00ff00'};


    const test = 180*24*60*60;
    const test2 = '180*24*60*60';
    const testInt =  parseInt(test2, 10);
    console.log('test', test, test2, testInt);
    return (
        <>
        <h1>Home</h1>
  

         </>
    )
}