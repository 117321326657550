const setConfig = () => {

    const dev = {

        Environment: 'development',

        Auth: {

            region: 'eu-west-1',
            userPoolId: 'eu-west-1_ewFpUl7fj',
            userPoolWebClientId: '2f6b21cponrdf1ddeg0h99fu1u',

            API: {
                endpoints: [
                    {
                        name: 'email-API-Gateway-dev',
                        endpoint: 'https://by054bjz04.execute-api.eu-west-1.amazonaws.com',
                    }
                ]
            }
        },
        ApiGateway: {

            apiName: 'email-API-Gateway-dev',
            apiPath: '/api/',

            apiInit: {
                headers: {
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Methods": "*",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: ""
                }
            }
        }
    }

    const stage = { // not configed
 
        Environment: 'staging',

        Auth: {
            region: 'eu-west-1',
            userPoolId: 'eu-west-1_ewFpUl7fj',
            userPoolWebClientId: '5ggkpjrh0iirmp2onev6o6ghoe',
            
            API: {
                endpoints: [
                    {
                        name: 'email-API-Gateway-dev',
                        endpoint: 'https://by054bjz04.execute-api.eu-west-1.amazonaws.com',
                    }
                ]
            }
        },
        ApiGateway: {  // stage uses the dev instance of api gateway

            apiName: 'email-API-Gateway-dev',
            apiPath: '/api/', // the (dev) environment for api gateway is provided by cloudfront

            apiInit: {
                headers: {
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Methods": "*",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: ""
                }
            }
        }
    }

    const prod = { // not configed

        Environment: '',

        Auth: {

            region: 'eu-west-2',
            userPoolId: 'eu-west-2_zsT2OtKTB',
            userPoolWebClientId: 'qlabq4kgk1l3865ujrhjm0a5t',
           
            API: {
                endpoints: [
                    {
                        name: "email-API-Gateway",
                        endpoint: "https://maildmarc.com",
                    }
                ]
            }
        },
        ApiGateway: {

            apiName: 'email-API-Gateway',
            apiPath: '/api/', // the (prod) environment for api gateway is provided by cloudfront

            apiInit: {
                headers: {
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Methods": "*",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: ""
                }
            }
        }
    };



    const env =  process.env.REACT_APP_STAGE == undefined ? "dev" : process.env.REACT_APP_STAGE.trim();

    switch (env) {
        case 'prod':
            return (prod);

        case 'stage':
            return (stage);

        case 'dev':
        default:
            return (dev);
    }
}




const config = setConfig();

const awsconfig = config.Auth;
const environment = config.Environment + ' 0v1';
const apiGateway = config.ApiGateway;
const logginglevel = 'VERBOSE';                                  // ERROR WARN INFO DEBUG VERBOSE

export { awsconfig, environment, apiGateway, logginglevel };
