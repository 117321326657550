// xxx@ts-nocheck

import { useContext, useEffect, useState } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css'
import { Col, Row, Spinner, Container, Accordion } from 'react-bootstrap'
import { DmarcContext } from './context'
import { LoadingFsm, dkimSelectors, Icon } from './const';


import { dmarcDetail } from './dmarcDetail'

import { DmarcData, Aggregate } from './types'

import {useNavigate, useLocation} from 'react-router-dom';

//import dmarcCheck from 'dmarc-parse'; 
//import spfCheck from 'spf-parse';
import { dkimCheck } from './dkimCheck';
const dmarcCheck = require('dmarc-parse');
const spfCheck   = require('spf-parse');

export type DNS = {
    data: string;
    name: string;
    type: string;
    request: string;
    intermediateName: string;
}

interface ViewDomainDetail {
    domain: string;
    report: Aggregate[];
    //dkimSelectors: string[];
}
  

export const ViewDomainDetail = (props:ViewDomainDetail) => {       // views detail as part of itengcated user request
    const { dmarc, setDmarc } = useContext(DmarcContext);               
    const [ fsm, setFsm ] = useState(LoadingFsm.UNDEFINED);
      
    const domain:string = props.domain;

    let selectors = props.report.map(item => (item.dkim_selector));  // create a list of dkim selectors
    selectors = Array.from(new Set(selectors));                // filter out du plicates
    selectors = selectors.filter(item => ((item !== '') && (item !== undefined)));         // delete any blank entry
 
    selectors = selectors.length > 0 ? selectors : dkimSelectors; // uses dkim selectors found in reports unless mepty in which case prefoned try list is used

    useEffect(() => {
        console.log('ViewDomainDetail useEffect');

        const dmarcIndex = dmarc.findIndex(item => item.domain === domain);

        if (fsm ===  LoadingFsm.UNDEFINED) {
              if (dmarc[dmarcIndex].hasOwnProperty('dns')) {
                setFsm((dmarc[dmarcIndex].dns!.length === 0) ? LoadingFsm.EMPTY : LoadingFsm.FOUND);
   
            } else {
                dmarcDetail(domain, selectors)
                .then (results => {

                    const newDetail:DNS[] = [];

                    results.forEach(item => {

                        switch (item.request) {
                            case 'dkim':
                                 newDetail.push({...item});
                                break;
                            case 'spf':
                                 if (item.data.startsWith('v=spf')) { newDetail.push({...item}) } ;               // only record with this start are allowed
                                break;
                            case 'dmarc':
                                if (item.data.startsWith('v=DMARC')) { newDetail.push({...item}) } ;              // only records with this start are allowed
                                break;
                            default:;
                        }

                    });

                    const newDmarc:DmarcData[] = [...dmarc];
                    newDmarc[dmarcIndex].dns = newDetail;
                    setDmarc(newDmarc);

                    console.log('ViewDomainDetail detail', newDetail, newDmarc, selectors);
                    setFsm((newDetail.length === 0) ? LoadingFsm.EMPTY : LoadingFsm.FOUND);
                });
            };
        }
    }, []);


    console.log('ViewDomainDetail fsm:', fsm, dmarc);

 
    if (fsm === LoadingFsm.FOUND) {

        const dmarcIndex = dmarc.findIndex(item => item.domain === domain);
        const dns:DNS[] = dmarc[dmarcIndex].hasOwnProperty('dns') ? dmarc[dmarcIndex].dns! : [];
   
        //return <ViewDomainDetailPure {...props} dns={[]}/> 
        return <ViewDomainDetailPure domain={domain} dkimSelectors={selectors}  dns={[]}/> 


    } else {
        return null;   
    }
};
  



export const ViewDNS = () => {                          // direct call from URL (unathenticated user)
    const location = useLocation();
    const path = location.pathname.split('/'); 
    const domain:string = path[path.length-1];

    // uses global predefined dkimSelectors list

    return ( 
        <ViewDomainDetailPure  domain={domain} dkimSelectors={dkimSelectors} dns={[]} /> 
    );
};
    


// does not use global contexts, so could be used on a standalone basis

interface ViewDomainDetailPure  {
    dns: DNS[]
    domain: string;
    //    domain: string;
    //report: Aggregate[];
    //dkimSelectors: string[];report: Aggregate[];
    dkimSelectors: string[];
}
export const ViewDomainDetailPure = (props:ViewDomainDetailPure) => { 
    const [ fsm, setFsm ] = useState(LoadingFsm.UNDEFINED);
    const [ dns, setDns ] = useState<DNS[]>([]);

    const domain:string = props.domain;

    useEffect(() => {
        // fetch dns values if they are not passed in props
        console.log('ViewDomainDetailPure useEffect');

        if (fsm ===  LoadingFsm.UNDEFINED) {
            if (props.dns.length !== 0) {
                setDns([...props.dns]);                  // set local state wuth dns
                setFsm(LoadingFsm.FOUND); 
            } else {
                // not passed in so get the dns list
                dmarcDetail(props.domain, props.dkimSelectors)
                .then (results => {

                    const newDns:DNS[] = [];

                    results.forEach(item => {
                        switch (item.request) {
                            case 'dkim':
                                newDns.push({...item});
                                break;
                            case 'spf':
                                if (item.data.startsWith('v=spf')) { newDns.push({...item}) } ;                  // only record with this start are allowed
                                break;
                            case 'dmarc':
                                if (item.data.startsWith('v=DMARC')) { newDns.push({...item}) } ;              // only records with this start are allowed
                                break;
                            default:;
                        }
                    });

                    console.log('ViewDomainDetailPure detail',  props.dkimSelectors, dns);

                    setDns(newDns);  
                    setFsm((newDns.length === 0) ? LoadingFsm.EMPTY : LoadingFsm.FOUND);
                });
            };
        }
    }, []);


    console.log('ViewDomainDetailPure fsm:', fsm, domain);

    switch (fsm) {
        default:
        case LoadingFsm.UNDEFINED:
        case LoadingFsm.INITIALISING:
            return (
            <Container fluid="lg" className="content_container">
                <Row>
                    <Col>
                        <div> <Spinner  animation="border" /></div>
                    </Col>
                </Row>
            </Container>
            );
            break; 
        case LoadingFsm.EMPTY:
            return (
            <Container fluid="lg" className="content_container">
                <Row>
                    <Col>
                        <div>No Domain Detail Found</div>
                    </Col>
                </Row>
            </Container>
            );
            break; 
        case LoadingFsm.FOUND:

            return (
                <div  key={'detail'}>

                    <ViewDmarcRecords title={'dmarc'}  dns={dns.filter(item => item.request === 'dmarc')}/>
                    <ViewSpfRecords title={'spf'}      dns={dns.filter(item => item.request === 'spf')}/>     
                    <ViewDkimRecords title={'dkim'}    dns={dns.filter(item => item.request === 'dkim')}/>

                </div>
            );
            break; 
        };
};

type ViewRecordValues = {
    name: string;
    value: string;
    description: string;
    prefix?: string;
    prefixdesc?: string;
}

type ViewData = {
    errors: string[];
    values: ViewRecordValues[];
    dns: DNS;
}

interface ViewXRecord  {
    title: string; 
    dns: DNS[];
}

const ViewSpfRecords = (props:ViewXRecord) => {

    const data:ViewData[] = props.dns.map(record => {

        const result = spfCheck(record.data);
        console.log('Spf Check', result);
        
        console.log('ViewSpfRecords', props, result);
    
        const errors:string[]            = result.hasOwnProperty('messages')   ? result.messages : [];
        const values:ViewRecordValues[]  = result.hasOwnProperty('mechanisms') ? result.mechanisms.map((key:any) => ({name: key.type , value: key.value, description: key.description, prefix: key.prefix, prefixdesc: key.prefixdesc})) : [];
        const dns:DNS                    = {...record};
    
        return ({errors, values, dns});
    });

    return (
        <ViewRecordWrapper title={props.title} viewData={data}/>
    );
} 

const ViewDmarcRecords = (props:ViewXRecord)  => {
  
    const data:ViewData[] = props.dns.map(record => {
    
        const result = dmarcCheck(record.data);
        console.log('dmarc Check', result);
    //result = dmarcCheck("abc");
    //result = dmarcCheck('v=DMARC;p=xquarantine;rua=mailto:rua@canterburybears.com,mailto:7ba4c8546@rua.easydmarc.us;ruf=mailto:ruf@canterburybears.com,mailto:7ba4c8546c@ruf.easydmarc.us;adkim=s;fo=1;')
  
        const keys:string[] = Object.keys(result.tags);

        console.log('ViewDmarcRecords', props, result, keys);

        const errors:string[]            = result.hasOwnProperty('messages') ? result.messages : [];
        const values:ViewRecordValues[]  = keys.map((key) => ({name: key, value: result.tags[key].value, description: result.tags[key].description}));

        const dns:DNS                    = {...record};

        return ({errors, values, dns});
    });


    return (
        <ViewRecordWrapper title={props.title} viewData={data}/>
    );
}




const ViewDkimRecords = (props:ViewXRecord)=> {

    /* test data
    const data:ViewData[] = [
                                {name: 'Empty',          data: '', type: '', request: '', intermediateName: ''},
                                {name: 'Illegal Tag',    data: 'hello=world', type: '', request: '', intermediateName: ''},
                                {name: 'Nothing in tag', data: 'p= ;', type: '', request: '', intermediateName: ''},
                                {name: 'No semicolon',   data: 'v=DKIM1 p=123;', type: '', request: '', intermediateName: ''},
                                {name: 'Duplicate',      data: 'p=123; p=456', type: '', request: '', intermediateName: ''},
                                {name: 'Wrong version',   data: 'v=DKIM2', type: '', request: '', intermediateName: ''},
                                {name: 'Version place wrong',   data: 'p=123; v=DKIM1', type: '', request: '', intermediateName: ''},
                                {name: 't wrong',   data: 'p=123; t=x', type: '', request: '', intermediateName: ''},
                                {name: 't correct y',   data: 'p=123; t=y', type: '', request: '', intermediateName: ''},
                                {name: 'r correct s',   data: 'p=123; t=s', type: '', request: '', intermediateName: ''},
            
    ].map(record => {
    */
        
    const data:ViewData[] = props.dns.map(record => {


        const result = dkimCheck(record.data);
        console.log('Dkim Check', result);

        console.log('ViewDkimRecords', props, result);
    
        const errors:string[]            = result.hasOwnProperty('messages')   ? result.messages : [];
        const values:ViewRecordValues[]  = result.hasOwnProperty('mechanisms') ? result.mechanisms.map((key:any) => ({name: key.type , value: key.value, description: key.description})) : [];
        const dns:DNS                    = {...record};

        console.log('dkimCheck output', errors, values);

        return ({errors, values, dns});
    });

    return (
        <ViewRecordWrapper title={props.title} viewData={data}/>
    )
} 

interface ViewRecordWrapper  {
    title: string;
    viewData: ViewData[];
}

const ViewRecordWrapper = (props:ViewRecordWrapper) => {


    let i=0; 
    var views:any[] = [];
    if (props.viewData.length > 1) {
        // xxx@ts-ignore
        views = props.viewData.map(item => <ViewRecord key={i++} title={item.dns.name} viewData={item}/>);
    } else {
        views = props.viewData.map(item => <ViewRecord key={i++} title={props.title} viewData={item}/>);
    }

    switch (props.viewData.length) {
        case 0:
            return ( <></> )
        case 1:
            return ( <>{views}</>)
        default:
            const pass = (props.viewData.findIndex(item => item.errors.length > 0) === -1); // pass if no erros found
            return (
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h3>{(pass) ? Icon.GOOD : Icon.BAD}&nbsp;&nbsp;{props.title}</h3></Accordion.Header>
                            <Accordion.Body>
                                {views}
                            </Accordion.Body>
                    </Accordion.Item>
                </Accordion>  
            )         
    }            
}

interface ViewRecord  {
    title: string;
    viewData: ViewData;
}

const ViewRecord = (props:ViewRecord) => {
    let i:number = 0;
    const errors = (props.viewData.errors.length > 0) ? <><h4>errors</h4><ul>{props.viewData.errors.map(item => <li key={i++}>{item}</li>)}</ul></> : "";
     
    const values = (props.viewData.values.length > 0) ? <><h4>tags</h4><ul>
                                                                    {props.viewData.values.map(item =>                                   
                                                                        <li style={{overflowWrap: 'break-word'}} key={i++}><b>{item.name}:</b> {item.value}           
                                                                            {item.hasOwnProperty('prefix') ? <ul><li><b>prefix:</b> {item.prefix} {(item.prefixdesc !== undefined) && `(${item.prefixdesc})`}</li></ul>:""}
                                                                            <ul>                                                 
                                                                                <li style={{overflowWrap: 'break-word'}}>{item.description}</li> 
                                                                            </ul> 
                                                                        </li>
                                                                    )}
                                                                    </ul></> : "";

    

    return (

        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header><h3>{(props.viewData.errors.length === 0) ? Icon.GOOD : Icon.BAD}&nbsp;&nbsp;{props.title}</h3></Accordion.Header>
                <Accordion.Body>
                    <h4>record</h4>
                    <ul>
                        <li key={i++}><b>name:</b> {props.viewData.dns.name}</li>
                        <li style={{overflowWrap: 'break-word'}} key={i++}><b>data:</b> {props.viewData.dns.data}</li>
                    </ul>
                    {errors}
                    {values}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>    
    )
}

